.panelButton {
    background-color: #f77f00;
    color: white;
    padding: 40px 20px;
    height: 7rem;
    display: inline-block;
    width: 16rem;
    text-align: center;
    border-radius: 12px;
    margin: 10px;
    cursor: pointer;
    vertical-align: top;
}

a.panelButton:link, a.panelButton:visited, a.panelButton:active {
    text-decoration: none;
    color: white;
}

.panelButton:hover {
    background-color: #fdba72;
}

.panel {
    width: 100%;
    font-weight: bold;
    font-size: 20pt;
}