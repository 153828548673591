.user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.user:nth-of-type(2n) {
    background-color: rgb(222, 252, 226);
}

.user:hover {
    background-color: rgb(252, 219, 200);
}

.user .email {
    flex: 3;
}

.user div {
    flex: 3;
}