.orderInfo {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    padding: 10px 0;
    border-bottom: 1px solid green;
}

.selectable {
    cursor: pointer;
}

.orderInfo span {
    flex: 5;
}

.orderInfo span:first-child {
    flex: 2;
}

.orderInfo span:last-child {
    flex: 1;
}