.info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
}

.info:nth-of-type(2n) {
    background-color: rgb(250, 223, 183);
}

.info:hover {
    background-color: rgb(156, 177, 158);
}

.info span {
    flex: 1;
}

.info span:nth-child(2) {
    flex: 3;
}

.info span:nth-child(4) {
    text-align: right;
}

.header .info:hover {
    background-color: #ddd;
}

.header {
    font-weight: bold;
    background-color: #ddd;
}