.customerEdit {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
}

.label {
  text-align: left;
  font-size: 0.7rem;
  height: 1rem;
  margin-left: 10px;
  width: 100%;
}

.value {
  border-width: 0 0 2px 0;
  border-color: rgb(41, 41, 141);
  font-size: 1rem;
  padding: 2px 10px;
  width: 100%;
}

.value:focus {
  outline: transparent;
}

@media (min-width: 30rem) {
  .label {
    font-size: calc(0.7rem + ((100vw - 30rem) / 70) * 0.3);
    height: calc(1rem + 0.7vw);
  }
  .value {
    font-size: calc(1rem + ((100vw - 30rem) / 70));
  }
}

@media (min-width: 100rem) {
  .label {
    font-size: 1rem;
    height: 1.5rem;
  }
  .value {
    font-size: 2rem;
  }
}
