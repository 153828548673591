.selectable {
    border: 1px solid lightblue;
}

.selectable:hover {
    border: 1px solid blue;
}

.edit {
    border: 1px solid black;
    padding: .375rem .75rem;
}