.workflow {
    border: 1px solid green;
    border-radius: 5px;
    margin-top: 10px;
}

div.title {
    color: white;
    background-color: green;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

span.title {
    flex: 6;
}

.body {
    padding: 10px;
    font-size: 1rem;
}