.statusInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.statusInfo:nth-child(even) {
    background-color: rgb(250, 223, 183);
}

.statusInfo:hover {
    background-color: rgb(156, 177, 158);
}

.statusInfo>span {
    flex: 1;
    padding: 5px;
}

.header>.statusInfo {
    font-weight: bold;
    background-color: #ddd;
    cursor: default;
}