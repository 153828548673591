.auditPopover {
    width: 620px;
    border: 1px solid rgb(153, 129, 129);
    border-radius: 5px;
    background-color: white;
}

.auditInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.auditInfo>span {
    padding: 0px 5px;
    color: rgb(147, 162, 170);
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.row>span {
    flex: 3;
    padding: 0 5px;
}

.row>span:nth-child(3) {
    flex: 1;
}

.row>span:last-child {
    flex: 8;
}