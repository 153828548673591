.statusSelect {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 6px 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.value {
    color: #000;
}

.placeholder {
    color: #888;
}

.selectItem {
    cursor: pointer;
}