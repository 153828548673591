.orderRow {
    display: flex;
    flex-direction: row;
}

.planned {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.customer {
    flex: 4;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 2px;
    margin-right: 8px;
}

.count {
    flex: 1;
    margin-right: 8px;
    margin-top: 0;
}

.count input {
    text-align: center;
}

.customerEdit button {
    flex: 1;
}