.paymentItemInfo {
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.paymentItemInfo:nth-of-type(2n) {
  background-color: rgb(222, 252, 226);
}

.paymentItemInfo:nth-child(1) {
  flex: 2;
  text-align: left;
}

.paymentItemInfo:hover {
  background-color: rgb(252, 219, 200);
}

.header .paymentItemInfo:hover {
  background-color: #ddd;
}

.paymentItemInfo span {
  padding: 5px;
  flex: 1;
  text-align: right;
}

.image {
  max-height: 40px;
}

.paymentItemInfo > span:first-child {
  flex: 1;
  text-align: left;
}

.paymentItemInfo > span:nth-child(2) {
  flex: 1;
  text-align: left;
}

.header {
  font-weight: bold;
  background-color: #ddd;
}
